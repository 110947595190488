<template>
    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="text" class="form-control" placeholder="First Name" v-model="form.firstname">
                    <span v-if="errors.hasOwnProperty('firstname')" class="text-white f-12">{{ errors.firstname[0] }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="text" class="form-control" placeholder="Last Name" v-model="form.lastname">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="tel" class="form-control" placeholder="Phone No." v-model="form.phone">
                    <span v-if="errors.hasOwnProperty('phone')" class="text-white f-12">{{ errors.phone[0] }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="email" class="form-control" placeholder="Email" v-model="form.email">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <textarea placeholder="Your Message" class="form-control" v-model="form.message"></textarea>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <p class="f-14 line-height-normal mt-3 text-gray p-1" :class="responseMessage ? 'alert alert-success' : ''" v-text="responseMessage"></p>
                    <div class="d-flex align-items-center justify-content-center">
                        <input type="submit" value="Submit" :class="custom_class">
                        <img src="https://i.gifer.com/ZKZg.gif" width="40px" class="ps-2" v-if="is_submitting">
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                message: "",
                ref_url: ""
            },
            responseMessage: "",
            is_submitting: false,
            errors: []
        }
    },

    props: {
        custom_class: null
    },

    methods: {
        submitForm() {
            this.is_submitting = true;
            this.errors = [];
            axios.post('/api/create/enquiry', this.form).then(response => {
                this.is_submitting = false;
                if (response.status === 200) {
                    this.responseMessage = "Thank you!! We will contact you soon.";
                } else {
                    this.responseMessage = "Something went wrong. Please try again";
                }
            }).catch(error => {
                this.is_submitting = false;
                this.errors = error.response.data.errors;
            })
        }
    }
}
</script>
