
import Vue from "vue";
import { Vuelidate } from "vuelidate";

Vue.use(Vuelidate)

import EnquiryComponent from "../views/vue/EnquiryComponent"
Vue.component("enquiry-component", require("../views/vue/EnquiryComponent").default);

if (document.getElementById("sticky-enquiry")) {
    new Vue({
        name: "StickyFormRoot",
        el: "#sticky-enquiry",
        components: {
            EnquiryComponent
        },
        data: {},
        mounted() {},
        methods: {}
    });
}

if (document.getElementById("faq-enquiry")) {
    new Vue({
        name: "FaqFormRoot",
        el: "#faq-enquiry",
        components: {
            EnquiryComponent
        },
        data: {},
        mounted() {},
        methods: {}
    });
}

if (document.getElementById("neha-corner-help-form")) {
    new Vue({
        name: "NehaCornerFormRoot",
        el: "#neha-corner-help-form",
        components: {
            EnquiryComponent
        },
        data: {},
        mounted() {},
        methods: {}
    });
}

if (document.getElementById("contact-form")) {
    new Vue({
        name: "ContactFormRoot",
        el: "#contact-form",
        data() {
            return {
                form: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    message: "",
                    lookingfor: [],
                    ref_url: ""
                },
                responseMessage: "",
                is_submitting: false,
                errors: []
            }
        },

        methods: {
            submitForm() {
                this.is_submitting = true;
                this.errors = [];
                axios.post('/api/create/enquiry', this.form).then(response => {
                    this.is_submitting = false;
                    if (response.status === 200) {
                        this.responseMessage = "Thank you!! We will contact you soon.";
                    } else {
                        this.responseMessage = "Something went wrong. Please try again";
                    }
                }).catch(error => {
                    this.is_submitting = false;
                    this.errors = error.response.data.errors;
                })
            }
        }
    });
}

if (document.getElementById("apply-for-job")) {
    new Vue({
        name: "JobFormRoot",
        el: "#apply-for-job",
        data() {
            return {
                form: {
                    fullname: "",
                    email: "",
                    phone: "",
                    attachment: null
                },
                responseMessage: ""
            }
        },

        methods: {
            submitForm() {
                const data = new FormData();
                data.append('attachment', this.form.attachment);
                data.append('fullname', this.form.fullname);
                data.append('email', this.form.email);
                data.append('phone', this.form.phone);

                axios.post('/api/job/apply', data).then(response => {
                    console.log(response.status);
                    if (response.status === 200) {
                        this.responseMessage = "Thank you!! We will contact you soon.";
                    } else {
                        this.responseMessage = "Something went wrong. Please try again";
                    }
                })
            },

            uploadAttachment(event) {
                this.form.attachment = event.target.files[0];
            }
        }
    });
}

if (document.getElementById("global_search")) {
    new Vue({
        name: "GlobalSearchRoot",
        el: "#global_search",
        data() {
            return {
                keywords: null,
                results: null
            }
        },

        watch: {
            keywords(after, before) {
                this.fetch();
            }
        },

        methods: {
            fetch() {
                if (this.keywords != null && this.keywords != '') {
                    axios.get('/api/search', { params: { keywords: this.keywords } })
                        .then(response => {
                            this.results = response.data;
                            this.renderResults();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    this.results = null;
                }
            },

            renderResults() {
                // Clear existing content
                const targetDiv = document.getElementById('search-results-container');
                if (!targetDiv) return;
        
                // Create the HTML string
                let html = '';
                if (this.results != null && this.results !== '') {
                    html += '<div class="search-suggestion-dropdown bg-white">';
        
                    // Service Section
                    if (this.results.Service && this.results.Service.length > 0) {
                        html += `
                            <div class="search-dropdown-content mb-3">
                                <h6 class="text-danger fw-700 mb-1">Service</h6>
                                <ul class="list-unstyled p-0 m-0">`;
                        this.results.Service.forEach(item => {
                            html += `
                                <li>
                                    <a href="${item.url}" target="_blank">${item.title}</a>
                                </li>`;
                        });
                        html += `</ul></div>`;
                    }
        
                    // Blog Section
                    if (this.results.Blog && this.results.Blog.length > 0) {
                        html += `
                            <div class="search-dropdown-content mb-3">
                                <h6 class="text-danger fw-700 mb-1">Blog</h6>
                                <ul class="list-unstyled p-0 m-0">`;
                        this.results.Blog.forEach(item => {
                            html += `
                                <li>
                                    <a href="${item.url}">${item.title}</a>
                                </li>`;
                        });
                        html += `</ul></div>`;
                    }
        
                    // Article Section
                    if (this.results.Article && this.results.Article.length > 0) {
                        html += `
                            <div class="search-dropdown-content mb-3">
                                <h6 class="text-danger fw-700 mb-1">Article</h6>
                                <ul class="list-unstyled p-0 m-0">`;
                        this.results.Article.forEach(item => {
                            html += `
                                <li>
                                    <a href="${item.url}" target="_blank">${item.title}</a>
                                </li>`;
                        });
                        html += `</ul></div>`;
                    }
        
                    html += '</div>';
                }
        
                // Append to target div
                targetDiv.innerHTML = html;
            },
        
            clearResults() {
                const targetDiv = document.getElementById('search-results-container');
                if (targetDiv) {
                    targetDiv.innerHTML = '';
                }
            }
        }
    });
}
