var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.firstname,
                  expression: "form.firstname",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "First Name" },
              domProps: { value: _vm.form.firstname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "firstname", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.hasOwnProperty("firstname")
              ? _c("span", { staticClass: "text-white f-12" }, [
                  _vm._v(_vm._s(_vm.errors.firstname[0])),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.lastname,
                  expression: "form.lastname",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Last Name" },
              domProps: { value: _vm.form.lastname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "lastname", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.phone,
                  expression: "form.phone",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "tel", placeholder: "Phone No." },
              domProps: { value: _vm.form.phone },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "phone", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.hasOwnProperty("phone")
              ? _c("span", { staticClass: "text-white f-12" }, [
                  _vm._v(_vm._s(_vm.errors.phone[0])),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.email,
                  expression: "form.email",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "email", placeholder: "Email" },
              domProps: { value: _vm.form.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "email", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.message,
                  expression: "form.message",
                },
              ],
              staticClass: "form-control",
              attrs: { placeholder: "Your Message" },
              domProps: { value: _vm.form.message },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "message", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("p", {
              staticClass: "f-14 line-height-normal mt-3 text-gray p-1",
              class: _vm.responseMessage ? "alert alert-success" : "",
              domProps: { textContent: _vm._s(_vm.responseMessage) },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-center",
              },
              [
                _c("input", {
                  class: _vm.custom_class,
                  attrs: { type: "submit", value: "Submit" },
                }),
                _vm._v(" "),
                _vm.is_submitting
                  ? _c("img", {
                      staticClass: "ps-2",
                      attrs: {
                        src: "https://i.gifer.com/ZKZg.gif",
                        width: "40px",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }